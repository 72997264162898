import React, { useState } from "react";
import {
  Container,
  Typography,
  List,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Paper,
  Box,
  Divider,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Card,
  CardContent,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

interface CartItem {
  id: number;
  name: string;
  price: number;
}

interface CheckoutFormProps {
  styleProp?: React.CSSProperties;
}

const dummyItems: CartItem[] = [
  { id: 1, name: "Item 1", price: 9.99 },
  { id: 2, name: "Item 2", price: 14.99 },
  { id: 3, name: "Item 3", price: 19.99 },
];

const CheckoutForm: React.FC<CheckoutFormProps> = ({ styleProp = {} }) => {
  const [cartItems, setCartItems] = useState<CartItem[]>(dummyItems);
  const [paymentMethod, setPaymentMethod] = useState<string>("creditCard");

  const handleDelete = (id: number) => {
    setCartItems(cartItems.filter((item) => item.id !== id));
  };

  const handlePaymentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaymentMethod(event.target.value);
  };

  const handleCheckout = () => {
    alert("Checkout complete");
    // Implement your checkout logic here
  };

  return (
    <Container maxWidth="sm">
      <Card>
        <CardContent style={styleProp}>
          <Box px={2} my={2}>
            <Typography variant="h4" fontWeight="600" gutterBottom>
              Checkout
            </Typography>
            {/* <Button variant="contained" color="primary" startIcon={<ShoppingCartIcon />}>
              View Cart
            </Button> */}
            <Paper elevation={0}>
              <List>
                {cartItems.map((item) => (
                  <React.Fragment key={item.id}>
                    <ListItem>
                      <ListItemText
                        primary={item.name}
                        secondary={`$${item.price.toFixed(2)}`}
                      />
                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => handleDelete(item.id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                  </React.Fragment>
                ))}
              </List>
            </Paper>
            <Box mt={4}>
              <Typography variant="h6" fontWeight="600" gutterBottom>
                Payment Method
              </Typography>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="payment-method"
                  name="paymentMethod"
                  value={paymentMethod}
                  onChange={handlePaymentChange}
                >
                  <FormControlLabel
                    value="creditCard"
                    control={<Radio />}
                    label="Credit Card"
                  />
                  <FormControlLabel
                    value="paypal"
                    control={<Radio />}
                    label="PayPal"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
            <Box mt={4}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleCheckout}
                fullWidth
              >
                Complete Checkout
              </Button>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
};

export default CheckoutForm;
