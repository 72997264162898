import React from "react";
import Card from "./Card";
import "./WelcomePage.css";

const WelcomePage: React.FC = () => {
  const cardData = {
    title: "Featured Product",
    description: "Discover our amazing featured product for unparalleled quality and design.",
    imageSrc: `${process.env.PUBLIC_URL}/store-image.png`
    // "https://via.placeholder.com/672x280"
  };

  return (
    <div className="welcome-page">
      <div className="welcome-content">
        <h1 className="welcome-heading">Welcome to Our Demo 1 Store</h1>
        <p className="welcome-text">Explore our amazing products and enjoy a seamless shopping experience.</p>
        <Card title={cardData.title} description={cardData.description} imageSrc={cardData.imageSrc} />
        <a href="/checkout" className="cta-button">
         Go To Checkout
        </a>
      </div>
    </div>
  );
};

export default WelcomePage;
